import React from "react";
import { Text } from '@chakra-ui/react'
// Chakra imports
import { Flex, Center, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

import Logo from "components/logo/logo";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
<Center  h='100px' color='white'>
    <Logo/>
</Center>

      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
