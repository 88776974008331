import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import TestImageLogo from "../../assets/img/landing/logo.jpg"

export default function Logo(props) {
  return (
    <Box {...props}>
      <Image
        borderRadius='full'
        boxSize='100px'
        objectFit='cover'
        src={TestImageLogo}
        alt='Aictor'
      />
    </Box>
  );
}
