import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdRecordVoiceOver
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

//Dashboard Imports
import HomeDashboard from "views/dashboard/default";
import Marketplace from "views/dashboard/marketplace";
import UserProfile from "views/dashboard/profile";
import DataTables1 from "views/dashboard/dataTables";
import RTL1 from "views/dashboard/rtl";
import Voices from "views/dashboard/voices";
import VoiceManagement from "views/dashboard/voicemangement";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Voice from "components/card/Voice";

const routes = [
  {
    name: "Your Dashboard",
    layout: "/dashboard",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: HomeDashboard,
  },
  {
    name: "Marketplace",
    layout: "/dashboard",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Marketplace,
    secondary: true,
  },
  {
    name: "Voices",
    layout: "/dashboard",
    path: "/voices",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Voices,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/dashboard",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables1,
  },
  {
    name: "Profile",
    layout: "/dashboard",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: UserProfile,
  },
  {
    name: "Your Voices",
    layout: "/dashboard",
    path: "/yourvoices",
    icon: <Icon as={MdRecordVoiceOver} width='20px' height='20px' color='inherit' />,
    component: VoiceManagement,
  },
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
  },
];

export default routes;
