import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    } from "@chakra-ui/modal";
    import { useDisclosure } from "@chakra-ui/hooks";

import React from 'react';
// Chakra imports
import {

    Button,
    useColorModeValue,
    SimpleGrid,
    Box,
    Checkbox,
    CheckboxGroup,
    Input,
    Text,
    Textarea

  } from "@chakra-ui/react";
    const DeleteVoiceModal = ( {onDelete, Id, Name} ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    
    let [name, setName] = React.useState('')
    let [description, setDescription] = React.useState('')
    let [url, setUrl] = React.useState('')
    //, description, setDescription, url, setUrl

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setName(inputValue)
      }

      const enabled = name == Name;

      async function TryDeleteVoice() {
        await onDelete(Id);
        onClose();
      }
    
    return (
    
    <Button
        onClick={onOpen}
        variant='darkBrand'
        color='white'
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Delete

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <SimpleGrid columns={[6, null, 1]} spacing='40px'>

                <Text fontSize='4xl'> Delete Voice </Text>
                <Text fontSize='lg'>Are you sure you want to delete this voice?</Text>
                <Text fontSize='lg'>If so, type the name of the voice '{Name}'</Text>
                    <Textarea
                        value={name}
                        onChange={handleInputChange}
                        placeholder='Here is a sample placeholder'
                        size='sm'
                    />
                <Button
                    onClick={() => { TryDeleteVoice() }} //onCreate(name, description, url)
                    color={textColorBrand}
                    fontWeight='500'
                    to='#art'
                    disabled={!enabled}
                    >
                    Delete
                </Button>
                <Button
                    onClick={onClose}
                    color={textColorBrand}
                    fontWeight='500'
                    to='#art'>
                    Cancel
                </Button>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
      </Button>
    ); };
    export default DeleteVoiceModal;