import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import LandingLayout from 'layouts/landing';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import DashboardLayout from 'layouts/dashboard';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
			<Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin + "/dashboard"
          }}
        >
          		<BrowserRouter>
					<Switch>
						{/* <Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} component={AdminLayout} />
						<Route path={`/rtl`} component={RtlLayout} /> */}
						<Route path={`/`} component={LandingLayout} />
						<Route path={`/home`} component={LandingLayout} />
						{/* <Route path={`/dashboard`} component={DashboardLayout} /> */}
					</Switch>
				</BrowserRouter>
        </Auth0Provider>

			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
