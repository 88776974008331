import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    } from "@chakra-ui/modal";
    import { useDisclosure } from "@chakra-ui/hooks";

import React from 'react';
// Chakra imports
import {

    Button,
    useColorModeValue,
    SimpleGrid,
    Box,
    Checkbox,
    CheckboxGroup,
    Input,
    Text,
    Textarea

  } from "@chakra-ui/react";
    const CreateVoiceModal = ( {onCreate} ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    
    let [name, setName] = React.useState('')
    let [description, setDescription] = React.useState('')
    let [url, setUrl] = React.useState('')
    //, description, setDescription, url, setUrl

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setName(inputValue)
      }
      let handleDescChange = (e) => {
        let inputValue = e.target.value
        setDescription(inputValue)
      }
      let handleUrlChange = (e) => {
        let inputValue = e.target.value
        setUrl(inputValue)
      }

      const enabled =
      name.length > 0 &&
      description.length > 0;

      async function TryCreateVoice() {
        await onCreate(name, description, url);
        onClose();
      }
    
    return (
    
    <Button
        onClick={onOpen}
        color={textColorBrand}
        fontWeight='500'
        me={{ base: "34px", md: "44px" }}
        to='#art'>
        New Voice + 

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <SimpleGrid columns={[6, null, 1]} spacing='40px'>

                <Text fontSize='4xl'> Create Voice </Text>
                <Box height='80px'>
                <Text fontSize='lg'>Name</Text>
                    <Textarea
                        value={name}
                        onChange={handleInputChange}
                        placeholder='Here is a sample placeholder'
                        size='sm'
                    />

                </Box>

                <Box height='80px'>
                    <Text fontSize='lg'>Description</Text>
                    <Textarea
                        value={description}
                        onChange={handleDescChange}
                        placeholder='Here is a sample placeholder'
                        size='sm'
                    />
                </Box>
                <Box height='80px'>
                    <Text fontSize='lg'>Image URL</Text>
                    <Textarea
                        value={url}
                        onChange={handleUrlChange}
                        placeholder='Default is a hamster'
                        size='sm'
                    />
                </Box>

                <Checkbox defaultChecked>SFW Guard</Checkbox>
                <Button
                    onClick={() => { TryCreateVoice() }} //onCreate(name, description, url)
                    color={textColorBrand}
                    fontWeight='500'
                    to='#art'
                    disabled={!enabled}
                    >
                    Create & Save
                </Button>
                <Button
                    onClick={onClose}
                    color={textColorBrand}
                    fontWeight='500'
                    to='#art'>
                    Exit
                </Button>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
      </Button>
    ); };
    export default CreateVoiceModal;