import {
    Button,
    Center,
    Container,
    Heading,
    Text,
    VStack,
    Box,
    keyframes,
    Wrap,
    WrapItem,
    SimpleGrid
  } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { motion, isValidMotionProp } from 'framer-motion';
import Voice from "components/card/Voice";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(1) rotate(2deg); }
  75% { transform: scale(1) rotate(-2deg);}
  100% { transform: scale(1) rotate(0);}
`;
const animation = `${animationKeyframes} 2s ease-in-out`;


const Examples = (props) => {
  const { loginWithRedirect } = useAuth0();

    return (
      
      <Container  py={[8, 10]} >

         <Center>
         <Heading size="2xl" mb={10} mt={10} color="gray.700">
              Example Voices
        </Heading>
          </Center>

          <SimpleGrid minChildWidth='250px' spacing='40px'>
          <Box bg='white' border='1px' borderColor='gray.200' borderRadius='10px' height='80px' as={motion.div} animation={animation}></Box>
          <Box bg='white' border='1px' borderColor='gray.200' borderRadius='10px' height='80px' as={motion.div} animation={animation}></Box>

        </SimpleGrid>
            <SimpleGrid >

            {/* <Box as={motion.div} animation={animation} >
                <Voice
                            name="Voice Name"
                            author="Description"
                            bidders={[
                                      Avatar1,
                                      Avatar2,
                                      Avatar3,
                                      Avatar4,
                                      Avatar1,
                                      Avatar1,
                                      Avatar1,
                                      Avatar1,
                                    ]}
                            image="{country.image_url}"
                            currentbid='0.91 ETH'
                            download='#'
                                  />
                </Box> */}

            </SimpleGrid>

      </Container>
    );
  };


export default Examples;