// Chakra imports
import { Portal, Box, useDisclosure, Text, Button, Link, Container } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/dashboard-sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import Header from 'components/header/header';
import LandingHero from 'components/hero/LandingHero';
import ExampleVoices from 'components/hero/ExampleVoices';
import Highlight from 'components/highlights/Highlight';
import Examples from 'components/hero/Examples';

import BotVectorPurple from '../../assets/img/landing/botvectorshort.svg';
import TopVectorPurple from '../../assets/img/landing/topvectorpurple.svg';
import FAQ from 'components/faq/FAQ';
import PistachioLines from '../../assets/img/landing/pistachiowaves.svg';
import BGStackedWaves from '../../assets/img/landing/stacked-waves-haikei-bg.svg';
import SimplePurpleWave from '../../assets/img/landing/wave-haikei.svg';
import PurpleWaves from '../../assets/img/landing/purplewaves.svg';
import Steps from '../../assets/img/landing/layered-steps-haikei.svg';

// Custom Chakra theme
export default function Landing(props) {

	const [screenSize, setScreenSize] = useState(getCurrentDimension());
  
	function getCurrentDimension(){
		return {
			width: window.innerWidth,
			height: window.innerHeight
		}
	}
  
	useEffect(() => {
	  const updateDimension = () => {
		  setScreenSize(getCurrentDimension())
	  }
	  window.addEventListener('resize', updateDimension);
  
  
	  return(() => {
		  window.removeEventListener('resize', updateDimension);
	  })
	}, [screenSize])
	return (
		<Box>
			<Box>
					<Box
						>

						<Container maxW={screenSize.width} mx="auto" backgroundSize='cover' borderTop='medium' backgroundImage={PistachioLines} color='#262626'>
							<Header/>
							
						</Container>
						<Container maxW={screenSize.width} mx="auto" backgroundSize='cover' borderTop='medium'  color='#262626'>
						<LandingHero  />
							
						</Container>
						
						<Container  maxW={screenSize.width} mx="auto" backgroundSize='cover' borderTop='medium' backgroundPosition='center' backgroundImage={Steps} color='#262626'>
						<ExampleVoices />
						</Container>


						<Container  maxW={screenSize.width} mx="auto" backgroundSize='cover' borderTop='medium' backgroundImage={SimplePurpleWave} color='#262626'>
						<Highlight />
						</Container>

						<Container maxW={screenSize.width} mx="auto" backgroundSize='cover' borderTop='medium' backgroundImage={BGStackedWaves} color='#262626'>
			
						</Container>

						

						{/* {props.children} */}
						<Box>
							<Footer />
						</Box>
					</Box>
			</Box>
		</Box>
	);
}
