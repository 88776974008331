import {
    Button,
    Center,
    Container,
    Heading,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import { FunctionComponent } from "react";
  import { useAuth0 } from "@auth0/auth0-react";
  import { motion } from "framer-motion";
  import { Link } from "react-router-dom";
  import SpeakingAnimation from "components/animations/Speaking";
  
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible
  };
  

const LandingHero = (props) => {
  const { loginWithRedirect } = useAuth0();
    return (
      <Container maxW="container.lg" >
            <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >

      <Center p={10}  >

          <VStack>
            <Container maxW="container.md" textAlign="left">
            <motion.h1
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible
                }}>
              <Heading size="2xl" mb={4} color="gray.700">
              AI Voices for Videos, Games, Podcasts...
              </Heading>
              </motion.h1>


                <Text fontSize="xl" color="gray.500">
                  We are an Artist first platform.
                </Text>
                {/* <Button
                mt={6}
                margin={1}
                size="lg"
                rounded="md"
                colorScheme="brand"
                onClick={() => loginWithRedirect()}>
              
                Try for Free
              </Button> */}
              <Button
                mt={6}
                margin={1}
                size="lg"
                rounded="md"
                textColor="black"
                colorScheme="white"
                bg='white' border='1px' borderColor='gray.200' borderRadius='10px'
>
              
                Coming Soon...
              </Button>
              <Text my={2} fontSize="sm" color="gray.500">
                102+ Actors have signed up in the last 30 days
              </Text>





            </Container>
          </VStack>
          <SpeakingAnimation/>
        </Center>
    </motion.article>

      </Container>
    );
  };


export default LandingHero;