import { Box, Container, keyframes, Text, useColorModeValue, VStack, Center } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Logo from 'components/logo/logo';

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(2) rotate(0); border-radius: 20%; }
  50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;
  
export default function Loading() {
    let logoColor = useColorModeValue("navy.700", "white");
  return (
    <VStack  h="100vh" display="flex" alignItems="center" justifyContent="center"
  spacing={20}
  align='stretch'
>
<Container display="flex" alignItems="center" justifyContent="center">
<Center  h='100px' color='white'>
<Text 
    me='auto'
    color={logoColor}
    fontSize='5xl'
    fontWeight='700'
    lineHeight='100%'>
    Loading...
    </Text>
</Center>

    </Container>
    <Container display="flex" alignItems="center" justifyContent="center">

    <Box
    as={motion.div}
    animation={animation}
    // not work: transition={{ ... }}
    padding="2"
    // @ts-ignore - "Does not exist" Type Error against Motion
    display="flex"
    >
      <Logo/>
    </Box>
    </Container>
</VStack>


  )
};