import {
    Button,
    Center,
    Container,
    Heading,
    Text,
    VStack,
  } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FAQ from "components/faq/FAQ";
import Highlight from "components/highlights/Highlight";

const ExampleVoices = (props) => {
  const { loginWithRedirect } = useAuth0();

    return (
      <Container boxShadow='outline' p='6' rounded='md' bg='white'>
        <Center >
        <Heading size="xl" mb={4} color="gray.700">
              FAQs
              </Heading>
        </Center>

            <FAQ />
      </Container>
    );
  };


export default ExampleVoices;