import { createClient } from '@supabase/supabase-js'

const getSupabase = (access_token) => {
  const options = {}

  if (access_token) {
    options.global = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  }

  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVraWZyaWFnZGd4cXF4aW5kYWd0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTkwMTcwMzgsImV4cCI6MjAxNDU5MzAzOH0.gsk2_3BzRN9hRTF5kYkO5MttFsL6nsLLr5rnSfUxiTU",
    options
  )

  return supabase
}



export { getSupabase }