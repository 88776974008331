import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text
} from '@chakra-ui/react';

  const FAQ = (props) => {
    return (
<Accordion allowMultiple>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Text fontSize="xl">
                  How can Voice Actors profit from this?
                </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Every time a voice line is spoken from one of our AIs, a charge is made and collected by a voice actor at a fair price, set by them on an open market of voices.
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Text fontSize="xl">
                  Can't the clone just be cloned?
                </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Technically yes, but at a noticeably worse quality.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Text fontSize="xl">
                  Why a Membership model?
                </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      A membership provides the voice actors and company a stable income, meaning we can garuantee improvements to the platform and run competitions.
    </AccordionPanel>
  </AccordionItem>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Text fontSize="xl">
                  How is this Tech for Good?
                </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      At the core of what we do is the Artist. We want to allow them the continue to work, and thrive, using the technologies which could otherwise have made the industry more unforgiving.
    </AccordionPanel>
  </AccordionItem>
</Accordion>

    );
  };
  

  export default FAQ;