/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/dashboard/voices/components/Banner";
import TableTopCreators from "views/dashboard/voices/components/TableTopCreators";
import HistoryItem from "views/dashboard/voices/components/HistoryItem";
import NFT from "components/card/NFT";
import EditVoice from "components/card/EditVoice";
import NewVoice from "components/card/NewVoice";
import Card from "components/card/Card.js";
import { useAuth0 } from "@auth0/auth0-react";

import CreateVoiceModal from "components/create-voice-modal/createvoice";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/dashboard/voices/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/dashboard/voices/variables/tableColumnsTopCreators";


//Supabase Client
import * as jose from 'jose'
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { getSupabase } from "utils/supabase";

export default function VoiceManagement() {
  const { user } = useAuth0();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  //Supabase
  const [countries, setCountries] = useState([]);
  const secret = new TextEncoder().encode('Aim3rsEKwvDZDV-K9xNz4K_SrAflAfD47Oj6rRuxuu__3c8jypKepQWpK2YXqjho');
  const alg = 'HS256'


  useEffect(() => {
    getUserVoices();
  }, []);

  async function getUserVoices() {
    const jwt = await new jose.SignJWT({ 'userId': user.sub, 'sub': user.sub})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer('urn:example:issuer')
    .setAudience('urn:example:audience')
    .setExpirationTime('2h')
    .sign(secret)

    const supabase = getSupabase(jwt)
    const { data } = await supabase.from("voice").select().eq('user_id', user.sub);

    setCountries(data);
  }
  
  async function CreateVoice(name, description, image_url) {
    const jwt = await new jose.SignJWT({ 'userId': user.sub, 'sub': user.sub})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer('urn:example:issuer')
    .setAudience('urn:example:audience')
    .setExpirationTime('2h')
    .sign(secret);

    const supabase = getSupabase(jwt);
    const { error } = await supabase
    .from('voice')
    .insert({ name: name, description: description, image_url: image_url, user_id: user.sub});
    console.log(error);

    getUserVoices();
  }

  async function DeleteVoice(id) {
    const jwt = await new jose.SignJWT({ 'userId': user.sub, 'sub': user.sub})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer('urn:example:issuer')
    .setAudience('urn:example:audience')
    .setExpirationTime('2h')
    .sign(secret);

    console.log("Deleting " + id);

    const supabase = getSupabase(jwt);
    const { error } = await supabase
    .from('voice')
    .delete()
    .eq('id', id);

    console.log(error);

    getUserVoices();
  }

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>

      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>

          <Flex direction='column'>
            <Flex
              mt='10px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                Your Voices
              </Text>
              <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <CreateVoiceModal onCreate={CreateVoice} />
              </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 4 }} gap='20px'>
              {/* Main Fields */}
              {countries.map((country) => (
                  <EditVoice
                      id= {country.id}
                      onDelete = {DeleteVoice}
                      name={country.name}
                      author={country.description}
                      bidders={[
                                Avatar1,
                                Avatar2,
                                Avatar3,
                                Avatar4,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                              ]}
                      image={country.image_url}
                      currentbid='0.91 ETH'
                      download='#'
                            />
                  ))}
                  <NewVoice
                      name="{country.name}"
                      author="{country.description}"
                      bidders={[
                                Avatar1,
                                Avatar2,
                                Avatar3,
                                Avatar4,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                              ]}
      
                      currentbid='0.91 ETH'
                      download='#'
                            />
            </SimpleGrid>

            
          </Flex>
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>

          <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Voice Performance
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>

            <HistoryItem
              name='Colorful Heaven'
              author='By Mark Benjamin'
              date='30s ago'
              image={Nft5}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Abstract Colors'
              author='By Esthera Jackson'
              date='58s ago'
              image={Nft1}
              price='0.91 ETH'
            />
            <HistoryItem
              name='ETH AI Brain'
              author='By Nick Wilson'
              date='1m ago'
              image={Nft2}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Swipe Circles'
              author='By Peter Will'
              date='1m ago'
              image={Nft4}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Mesh Gradients '
              author='By Will Smith'
              date='2m ago'
              image={Nft3}
              price='0.91 ETH'
            />
            <HistoryItem
              name='3D Cubes Art'
              author='By Manny Gates'
              date='3m ago'
              image={Nft6}
              price='0.91 ETH'
            />
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
