// src/components/AnimationPage.jsx

import React, { Component } from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './jsons/speaker.json'

export default function Speaking() {
    return (
      <Lottie
        loop
        animationData={lottieJson}
        play
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        style={{ height: '100%' }}
      />
    )
  }