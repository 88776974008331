import {
    Box,
    Center,
    Container,
    Wrap,
    WrapItem,
    Text,
    Image,
    VStack,
    SimpleGrid,
    Heading
  } from "@chakra-ui/react";
  // ...

  
  const highlights = [
    {
      icon: "✨",
      title: "Problem",
      description:
        "Voice Actors are getting screwed and the problem is about to get worse. AI Voices will continue to take away work from voice actors, and these algorithms will only get better.",
    },
    {
      icon: "🎉",
      title: "Solution",
      description:
        "Our platform allows voice actors to leverage AI to work for a fair price. Allowing them to use their artistic talent to create characters and voices which can work for them.",
    },

  ];
  
  const Highlight = (props) => {
    return (
      <Box>
        
        <Container maxW="container.md" centerContent py={[8, 28]}>
        <Heading size="2xl" mb={10} mt={10} color="gray.700">
            Why?
        </Heading>
          <SimpleGrid spacingX={10} spacingY={20} minChildWidth="300px">
            {highlights.map(({ title, description, icon }, i ) => (
              <Box p={4} rounded="md" key={`highlight_${i}`}>
                <Text fontSize="4xl">{icon}</Text>
  
                <Text fontWeight={500}>{title}</Text>
  
                <Text  mt={4}>
                  {description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    );
  };
  

  export default Highlight;