/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/dashboard/voices/components/Banner";
import TableTopCreators from "views/dashboard/voices/components/TableTopCreators";
import HistoryItem from "views/dashboard/voices/components/HistoryItem";
import NFT from "components/card/NFT";
import Voice from "components/card/Voice";
import Card from "components/card/Card.js";
import { useAuth0 } from "@auth0/auth0-react";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/dashboard/voices/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/dashboard/voices/variables/tableColumnsTopCreators";


//Supabase Client
import * as jose from 'jose'
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { getSupabase } from "utils/supabase";

export default function Voices() {
  const { user } = useAuth0();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  //Supabase
  const [countries, setCountries] = useState([]);
  const secret = new TextEncoder().encode('Aim3rsEKwvDZDV-K9xNz4K_SrAflAfD47Oj6rRuxuu__3c8jypKepQWpK2YXqjho');
  const alg = 'HS256'


  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const jwt = await new jose.SignJWT({ 'userId': user.sub, 'sub': user.sub})
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer('urn:example:issuer')
    .setAudience('urn:example:audience')
    .setExpirationTime('2h')
    .sign(secret)

    const supabase = getSupabase(jwt)
    //const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVraWZyaWFnZGd4cXF4aW5kYWd0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTkwMTcwMzgsImV4cCI6MjAxNDU5MzAzOH0.gsk2_3BzRN9hRTF5kYkO5MttFsL6nsLLr5rnSfUxiTU", options);
    const { data } = await supabase.from("voice").select();

    setCountries(data);
  }

  return (
    <Box  pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Box bg='white' w='100%' p={4} color='white' borderWidth='1px' borderRadius='lg'>
      <Grid
        mb='20px'
        
        display={{ base: "block", xl: "grid" }}>
        <Flex
          
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>

          <Flex direction='column'>
            <Flex
              mt='10px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                All Voices
              </Text>
              <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#art'>
                  Top ⭐
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#music'>
                  Trending 🔥
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#collectibles'>
                  New 💚
                </Link>

              </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 4 }} gap='20px'>
              {/* Main Fields */}
              {countries.map((country) => (
                  <Voice
                      name={country.name}
                      author={country.description}
                      bidders={[
                                Avatar1,
                                Avatar2,
                                Avatar3,
                                Avatar4,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                                Avatar1,
                              ]}
                      image={country.image_url}
                      currentbid='0.91 ETH'
                      download='#'
                            />
                  ))}

            </SimpleGrid>

            
          </Flex>
        </Flex>

      </Grid>
      </Box>

      {/* Delete Product */}
    </Box>
  );
}
